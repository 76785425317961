import React from 'react';

import { NavigationType } from '../../../Header/Header';

import { DrillDownPanelProvider } from '../DrillDownPanelContext/DrillDownContext';
import TreatmentsMegaMenu from './TreatmentsMegaMenu/TreatmentsMegaMenu';
import ConditionsMegaMenu from './ConditionsMegaMenu/ConditionsMegaMenu';
import BrandsMegaMenu from './BrandsMegaMenu/BrandsMegaMenu';

type NavItem = NavigationType['items'][number];

interface Props {
    parentNavKey?: string;
    navItem: NavItem;
    treatmentCount?: number;
    conditionCount?: number;
}

const customMenus = {
    treatments: TreatmentsMegaMenu,
    conditions: ConditionsMegaMenu,
    brands: BrandsMegaMenu,
};

/**
 * Gets the custom menu type if given.
 */
export const getCustomMenuName = (classes: NavItem['classes']) => {
    if (!classes) {
        return '';
    }

    const megaMenuClass = classes.split(' ').find((className: string) => (
        className.includes('_has-megamenu--')
    )) || '';

    return megaMenuClass.replace('_has-megamenu--', '');
};

/**
 * Works out if a custom menu should be shown.
 */
export const shouldCustomMenuBeShown = (name: ReturnType<typeof getCustomMenuName>, pathName: string) => {
    // If it's the brands menu item and we're on a GPPC page.
    if (name === 'brands' && pathName.includes('/gppc')) {
        return false;
    }

    // If it's the treatments menu item and we're on the homepage or GPPC page.
    if (name === 'treatments' && (pathName === '/' || pathName.includes('/gppc') || pathName.includes('/ad/'))) {
        return false;
    }

    return true;
}

/**
 * Works out what sub menu type will be displayed.
 */
export const getNavChildType = (navItem: NavItem) => {
    // If the nav item has the class for reorders.
    if (navItem?.classes?.includes('reorders')) {
        return 'reorders'
    }

    // If there are no child items, there's no menu to display.
    if (!navItem.children?.length) {
        return '';
    }

    // If there is a custom menu and it's one of the correct types
    const customMenuType = getCustomMenuName(navItem.classes);
    if ((Object.keys(customMenus) as string[]).includes(customMenuType)) {
        return 'customMenu';
    }

    // Otherwise, it's just a simple dropdown.
    return 'dropdown';
}

/**
 * Displays a custom menu with the required panel context.
 */
const CustomMenu = ({ parentNavKey, navItem, treatmentCount, conditionCount }: Props) => {
    const CustomMenu = customMenus[getCustomMenuName(navItem.classes) as keyof typeof customMenus];

    if (!CustomMenu) {
        return null;
    }

    return (
        <DrillDownPanelProvider parentNavKey={parentNavKey}>
            <CustomMenu
                navItems={navItem.children || []}
                treatmentCount={treatmentCount || 1079}
                conditionCount={conditionCount || 90}
            />
        </DrillDownPanelProvider>
    );
};

export default CustomMenu;
