export const animationDuration = 6000; // Milliseconds

let timeout: ReturnType<typeof setTimeout> | undefined;

const bannerSelector = '[data-site-banner=true] li';

const handleAnimationStart = (e: Event) => {
    const li = e.target as HTMLElement;

    if (!li.getAnimations) {
        return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => {
        const nextLi = li.nextSibling;

        if (nextLi) {
            (nextLi as HTMLElement).getAnimations().forEach((animation) => {
                animation.play();
            });
        } else {
            const firstLi = document.querySelector(bannerSelector);

            firstLi?.getAnimations().forEach((animation) => {
                animation.play();
            });
        }
    }, animationDuration - 900);
}

export const stopAnimations = () => {
    clearTimeout(timeout);

    const listItems = document.querySelectorAll(bannerSelector)!;

    listItems.forEach((li) => {
        li.getAnimations().forEach((animation) => {
            animation.finish();
        });
    });
}

export const resetAnimationCycle = () => {
    stopAnimations();

    const listItems = document.querySelectorAll(bannerSelector) as unknown as HTMLElement[];

    listItems.forEach((li) => {
        // eslint-disable-next-line no-param-reassign
        li.onanimationstart = handleAnimationStart;
    });

    listItems[0].getAnimations().forEach((animation) => {
        animation.play();
    });
};

export const clearAnimationTimeout = () => {
    clearTimeout(timeout);
    timeout = undefined;
}
