'use client';

import React, { useMemo } from 'react';

import { useAuthContext } from '../../../data/context/authContext';
import { useSetAccountMenuOpenContext, useIsAccountMenuOpenContext } from '../../../data/context/SiteNavigationContext';
import { NavigationType } from '../Header/Header';
import usePatientReorders from '../DrillDown/DrillDownPanel/CustomMenu/ReorderList/hooks/usePatientReorders';
import useIsClient from '../../../hooks/useIsClient';

import DrillDown from '../DrillDown/DrillDown';

const manualNavItems = [
    {
        _key: 'account-dashboard',
        _type: 'navigationItem',
        link: '/account',
        title: 'Account dashboard',
        type: 'custom',
        enabled: true,
    },
    {
        _key: 'order-history',
        _type: 'navigationItem',
        link: '/account/orders',
        title: 'Order history',
        type: 'custom',
        enabled: true,
    },
    {
        _key: 'billing-delivery',
        _type: 'navigationItem',
        link: '/account/delivery-addresses',
        title: 'Billing & delivery',
        type: 'custom',
        enabled: true,
    },
    {
        _key: 'medical-records',
        _type: 'navigationItem',
        link: '/account/personal-medical-record',
        title: 'Medical records',
        type: 'custom',
        enabled: true,
    },
    {
        _key: 'manage-account',
        _type: 'navigationItem',
        link: '/account/settings',
        title: 'Manage account',
        type: 'custom',
        enabled: true,
    }
] as NavigationType['items'];

const AccountNavigation = () => {
    const { isLoggedIn, patientName, logout } = useAuthContext();
    const isClient = useIsClient();

    const setNavigationOpen = useSetAccountMenuOpenContext();
    const isNavigationOpen = useIsAccountMenuOpenContext();

    const handleClose = () => {
        setNavigationOpen(false);
    }

    const handleLogout = () => {
        logout();
    }

    const { reorders } = usePatientReorders();

    const navigationItems = useMemo(() => {
        if (!reorders.length) {
            return manualNavItems;
        }

        const manualNavItemsClone = [...manualNavItems];
        manualNavItemsClone.splice(2, 0, {
            _key: 'treatment-reorders',
            _type: 'navigationItem',
            link: '',
            title: 'Treatment reorders',
            type: 'group',
            enabled: true,
            classes: 'reorders',
        });

        return manualNavItemsClone;
    }, [reorders]);

    return (
        <DrillDown
            title={isClient && isLoggedIn ? `Hello, ${patientName || 'user'}` : 'Sign In'}
            label="Account menu"
            navItems={navigationItems}
            isOpen={isNavigationOpen}
            onClose={handleClose}
        >
            <li>
                <button
                    type="button"
                    className="text-status-error-default font-700 w-full text-left hover:underline"
                    onClick={handleLogout}
                >
                    Sign out
                </button>
            </li>
        </DrillDown>
    );
};

export default AccountNavigation;
